export type CompanyInfo = {
    address: {
        country: string;
        region: string;
        city: string;
        postCode: string;
        street: string;
    };
    billingEmail: string;
    billingFirstName: string;
    billingLastName: string;
    businessSize: string;
    id: string;
    name: string;
    owner: string;
    preferredPaymentMethod: `${PaymentMethod}`;
    vatId: string;
};

export type UpdateCompanyResponse = {
    address: {
        country: string;
        region: string;
        city: string;
        postCode: string;
        street: string;
    };
    billingFirstName: string;
    billingLastName: string;
    billingEmail: string;
    businessSize: string;
    createdAt: string;
    freePartnerLicenses: number;
    id: string;
    industry: string;
    members: CompanyMember[];
    name: string;
    owner: string;
    preferredPaymentMethod: `${PaymentMethod}`;
    updatedAt: string;
    vatId: string;
};

export type CompanyMember = {
    role: `${CompanyRole}`;
    user: string;
    joinedAt: string;
};

export enum CompanyRole {
    superAdmin = 'super_admin',
    admin = 'admin',
    readOnly = 'read_only'
}

export enum PaymentMethod {
    BANK_TRANSFER = 'bank-transfer',
    CREDIT_CARD = 'credit-card',
    PAYPAL = 'paypal'
}
