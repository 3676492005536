import React from 'react';

import type { IconType } from '@ninox/ninox-components/lib/Icon';

import { Container, Content, Title } from './InfoCard.styles';
import { LargeIcon } from '../LargeIcon';
import { Color, Size } from '@ninox/ninox-components/theme';

type Properties = {
    content: string;
    icon: IconType;
    title: string;
};

export const InfoCard = ({ content, icon, title }: Properties) => {
    return (
        <Container>
            <LargeIcon icon={icon} options={{ color: Color.neutral['1000'], filling: 1, size: Size.m }} />
            <Title>{title}</Title>
            {content && <Content>{content}</Content>}
        </Container>
    );
};
