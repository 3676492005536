import type { LoaderFunction } from 'react-router-dom';
import { defer, redirect } from 'react-router-dom';

import { PublicClient } from '../../../../api/public';
import type { OrderDetailItem, OrderOverviewItem } from '../../../../models/ManageClouds/Orders';
import type { CompanyInfo } from '../../../../models/ManageClouds/CompanyInfo';

export type LoaderData = {
    companyInfo: CompanyInfo;
    history: OrderDetailItem[];
    order: OrderOverviewItem;
};

export const loader = (async ({ params }) => {
    const domain = params.domain;

    if (!domain) {
        return redirect('..');
    }

    const order = await PublicClient.getOrder(domain);

    if (!order) {
        return redirect('..');
    }

    const history = await PublicClient.getOrderHistory(domain);
    const companyInfo = await PublicClient.getCompanyInfo();

    return defer({ companyInfo, history, order });
}) satisfies LoaderFunction;
